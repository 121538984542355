<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div
    class="group cursor-pointer rounded-card bg-[url('/images/cvReviewBg.png')] p-[1.25rem] text-white"
    style="border-radius: 0.625rem"
  >
    <div class="flex flex-row items-center justify-between border-b-[1px]">
      <div class="font-bold">
        {{ t('jobBoard.cvReview.bannerTextBeforeReview') }}
      </div>
      <div class="pr-[2rem]"><img src="/images/dinosaur.png" alt="dinosaur" /></div>
    </div>
    <div class="flex flex-row justify-between py-[0.62rem] text-t6 text-white">
      <div class="font-medium group-hover:underline">Grab your chance and get start!</div>
      <div class="w-[1.125rem]">
        <IconsFigmaArrowForwardOutlined />
      </div>
    </div>
  </div>
</template>
